<template>
  <div>
    <h1 />
    <div class="apply-for-project-form">
      <v-row>
        <v-col offset-md="2" md="8" sm="12">
          <div class="text-h5 text-md-h4 text-center mb-4 main-title tway-gray-dark--text">Postulación de Proyecto</div>
          <div class="mb-4">
            <v-stepper class="elevation-0" v-model="stepLabel" alt-labels>
              <v-stepper-header class="pl-0 pr-0">
                <v-stepper-step class="mr-md-8" step="1" color="tway-violet" :complete="stepLabel > 1">
                  Antecedentes Generales
                </v-stepper-step>
                <v-divider :color="stepLabel > 1 ? 'tway-violet' : null" />
                <v-stepper-step class="mr-md-8 ml-md-8" step="2" color="tway-violet" :complete="stepLabel > 2">
                  Organización
                </v-stepper-step>
                <v-divider :color="stepLabel > 2 ? 'tway-violet' : null" />
                <v-stepper-step class="mr-md-8 ml-md-8" step="3" color="tway-violet" :complete="stepLabel > 3">
                  Equipo
                </v-stepper-step>
                <v-divider :color="stepLabel > 3 ? 'tway-violet' : null" />
                <v-stepper-step class="ml-md-8" step="4" color="tway-violet" :complete="stepLabel > 4">
                  Confirmación
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </div>
          <router-view />
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0 mb-5">
        <v-col offset-md="2" md="8" offset-sm="0" class="d-flex justify-space-between align-center">
          <div class="d-inline">
            <v-btn
              large
              depressed
              rounded
              class="roboto elevation-1"
              outlined
              color="deep-purple accent-4"
              @click="back"
            >
              Atras
            </v-btn>
          </div>
          <div class="d-inline roboto mr-2">
            <v-btn
              v-if="stepIndex !== 3"
              class="text-uppercase text-body-1 roboto lower"
              rounded
              text
              x-small
              color="deep-purple accent-4"
              @click="save"
            >
              GUARDAR FORMULARIO
            </v-btn>
            <v-btn
              v-if="stepIndex == 3"
              class="button-text white--text"
              rounded
              depressed
              color="deep-purple accent-4"
              @click="$router.replace({ name: 'my-projects' })"
            >
              VOLVER A MIS PROYECTOS
            </v-btn>
          </div>
          <div class="d-inline">
            <v-btn
              v-if="stepIndex == 3 && tenderRequest.status && tenderRequest.status.id < 3"
              large
              depressed
              rounded
              :disabled="!formCompleted"
              @click="modalSend = true"
              class="tway-violet white--text roboto elevation-3 text-upper"
            >
              Enviar Postulación
            </v-btn>
            <v-btn
              v-if="stepIndex !== 3"
              large
              depressed
              rounded
              class="tway-violet white--text roboto elevation-3"
              @click="next"
            >
              Siguiente
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="modalCancel" max-width="500">
      <v-card class="py-4 px-7" style="border-radius: 4px !important">
        <v-card-title class="headline mb-3" :class="!desktop ? 'pt-8' : ''">
          <v-row class="ma-0 pa-0" justify="center">
            <span class="title-modal text-center"
              >¿Realmente quieres volver a Proyectos en Tway sin enviar tu postulación?</span
            >
          </v-row>
        </v-card-title>

        <v-card-text class="headline tway-gray-dark--text" style="font-size: 16px !important; line-height: 20px">
          <v-row class="px-1 modal-text" justify="center">
            <span class="text-center">
              Al volver a la sección de Proyectos en Tway, este proceso de postulación quedará incompleto. Podrás
              continuar con el envío de postulación en cualquier momento.
            </span>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-col v-if="desktop" class="text-center mt-4">
            <a
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              class="tway-violet--text roboto"
              v-if="desktop"
              :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
              @click.stop="modalCancel = false"
            >
              CANCELAR
            </a>
            <v-btn
              style="font-weight: bold; letter-spacing: 0.1em"
              v-if="desktop"
              rounded
              color="tway-violet"
              class="elevation-2 white--text roboto"
              @click="$router.replace({ name: 'my-projects' })"
            >
              SI, VOLVER A PROYECTOS
            </v-btn>
          </v-col>
          <v-row v-if="!desktop" class="ma-0 pa-0" justify="center">
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <v-btn
                  style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  rounded
                  color="tway-violet"
                  class="elevation-2 pa-6 white--text roboto"
                  @click="$router.replace({ name: 'my-projects' })"
                >
                  SI, VOLVER A PROYECTOS
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <a
                  style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  class="tway-violet--text roboto"
                  v-if="!desktop"
                  :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
                  @click.stop="modalCancel = false"
                >
                  CANCELAR
                </a>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalSend" max-width="500">
      <v-card class="py-4 px-7" style="border-radius: 4px !important">
        <v-card-title
          class="headline mb-3"
          :class="!desktop ? 'pt-8' : ''"
          :style="!desktop ? 'font-size: 20px !important;' : ''"
        >
          <v-row class="ma-0 pa-0" justify="center">
            <span class="text-center">Confirmación de Postulación</span>
          </v-row>
        </v-card-title>

        <v-card-text class="headline tway-gray-dark--text" style="font-size: 18px !important">
          <v-row class="px-1 modal-text" justify="center">
            <span class="text-center">
              Al confirmar tu postulación, podrás ver el estado y seguimiento del proyecto en la sección “Proyectos en
              Tway”.Recuerda que una vez confirmada la postulación, no podrás realizar cambios en la información
              entregada.
            </span>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-col v-if="desktop" class="text-center">
            <a
              style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
              class="tway-violet--text roboto"
              v-if="desktop"
              :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
              @click.stop="modalSend = false"
            >
              CANCELAR
            </a>
            <v-btn
              style="font-weight: bold; letter-spacing: 0.1em"
              v-if="desktop"
              rounded
              color="tway-violet"
              class="elevation-2 white--text roboto"
              @click="completeRequest"
            >
              CONFIRMAR POSTULACIÓN
            </v-btn>
          </v-col>
          <v-row v-if="!desktop" class="ma-0 pa-0" justify="center">
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <v-btn
                  style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  rounded
                  color="tway-violet"
                  class="elevation-2 pa-6 white--text roboto"
                  @click="completeRequest"
                >
                  CONFIRMAR POSTULACIÓN
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row class="pa-0 ma-0" justify="center">
                <a
                  style="font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  class="tway-violet--text roboto"
                  v-if="!desktop"
                  :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
                  @click.stop="modalSend = false"
                >
                  CANCELAR
                </a>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CallForTenderService from "@/services/callForTenderService";
import store from "@/store";

export default {
  name: "ApplyForProject",
  beforeRouteUpdate(to, from, next) {
    let index = to.meta.step;
    if (!this.stepIsLocked(index)) {
      this.stepIndex = index;
      next();
      window.scrollTo(0, 0);
    } else {
      next({
        path: index > 0 ? this.getStep(index - 1).path : "general-data",
        replace: true,
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0);

    let index = to.meta.step;
    let id = to.params.id;
    let tenderRequest = store.getters["applyForProject/getTenderRequest"];

    if (id.indexOf("project=") > -1) {
      next();
      return;
    }

    if (!tenderRequest || tenderRequest.id !== id) {
      CallForTenderService.getTenderRequest(id)
        .then((resp) => {
          if (resp) {
            if (resp.period == null || resp.period == "") {
              resp.period = {
                startDate: "",
                endDate: "",
              };
            } else {
              if (resp.period.startDate == null || resp.period.startDate == "") {
                resp.period.startDate = "";
              }
              if (resp.period.endDate == null || resp.period.endDate == "") {
                resp.period.endDate = "";
              }
            }
            store.dispatch("applyForProject/setFormDataFromTenderRequest", resp);
            let lastStep = store.getters["applyForProject/getLastComplete"];

            next({
              path: "general-data",
              replace: true,
            });
            next({
              path: lastStep.step.path,
              replace: true,
            });
          } else {
            next({ name: "my-projects" });
          }
        })
        .catch((err) => {
          console.log(err);
          next({ name: "my-projects" });
        });
    } else if (index !== 0) {
      next({ name: "apply-for-project" });
    } else {
      next();
    }

    next();
  },
  destroyed() {
    this.resetAll();
  },
  data() {
    return {
      stepIndex: 0,
      modalSend: false,
      modalCancel: false,
      project: {},
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      stepIsLocked: "applyForProject/getStepIsLocked",
      stepIsComplete: "applyForProject/getStepIsComplete",
      getStep: "applyForProject/getStep",
      isLastStep: "applyForProject/getIsLastStep",
      formCompleted: "applyForProject/getIsAllComplete",
      tenderRequest: "applyForProject/getTenderRequest",
      state: "applyForProject/state",
    }),
    stepLabel() {
      return this.stepIndex + 1;
    },
    projectId() {
      return this.$route.params.id;
    },
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
  methods: {
    ...mapActions({
      resetAll: "applyForProject/resetAll",
      updateTenderRequest: "applyForProject/updateTenderRequest",
      unlockStep: "applyForProject/unlockStep",
      setTenderRequest: "applyForProject/setTenderRequestFromDB",
      resetStep: "applyForProject/resetStep",
      setStepData: "applyForProject/setStepData",
    }),
    next() {
      this.updateTenderRequest().then(() => {
        let stepData = this.getStep(this.stepIndex);
        let formData = { ...stepData.data };
        this.setStepData({
          index: this.stepIndex,
          data: { ...formData },
        });

        let tenderRequest = { ...this.tenderRequest };

        let id = this.$route.params.id;
        if (id.indexOf("project=") > -1) {
          let projectInfo = id.split("=");
          tenderRequest.projectId = projectInfo[1];
        }

        CallForTenderService.saveRequestTender(tenderRequest).then((saved) => {
          this.setTenderRequest(saved).then(() =>
            CallForTenderService.addTenderRequestAttachments(saved.id, tenderRequest)
              .then(() => {
                this.unlockStep(this.stepIndex + 1);
                this.$router.push(this.getStep(this.stepIndex + 1).path);
              })
              .catch((error) => this.$log.error(error))
          );
        });
      });
    },
    back() {
      if (this.tenderRequest && this.tenderRequest.status && this.tenderRequest.status.id < 3) {
        let prevStep = this.stepIndex - 1;
        if (prevStep >= 0) {
          this.$router.push(this.getStep(prevStep).path);
        } else {
          this.$router.replace({ name: "my-projects" });
        }
      } else {
        this.$router.replace({ name: "my-projects" });
      }
    },
    save() {
      this.updateTenderRequest().then(() => {
        let tenderRequest = { ...this.tenderRequest };
        let id = this.$route.params.id;
        if (id.indexOf("project=") > -1) {
          let projectInfo = id.split("=");
          tenderRequest.projectId = projectInfo[1];
        }
        CallForTenderService.saveRequestTender(tenderRequest)
          .then((saved) => {
            CallForTenderService.addTenderRequestAttachments(saved.id, tenderRequest)
              .then(() => this.$router.replace({ name: "my-projects" }))
              .catch((error) => this.$log.error(error));
          })
          .catch((error) => this.$log.error(error));
      });
    },
    completeRequest() {
      let tenderRequest = { ...this.tenderRequest };
      let id = this.$route.params.id;
      if (id.indexOf("project=") > -1) {
        let projectInfo = id.split("=");
        tenderRequest.projectId = projectInfo[1];
      }
      CallForTenderService.saveRequestTender(tenderRequest)
        .then((saved) => {
          CallForTenderService.addTenderRequestAttachments(saved.id, tenderRequest)
            .then(() => {
              CallForTenderService.completeTenderRequest({
                id: tenderRequest.id ? tenderRequest.id : saved.id,
              }).then((complete) =>
                this.$router.replace({
                  name: "my-projects/request-created",
                  params: { tenderRequestId: complete.id },
                })
              );
            })
            .catch((error) => this.$log.error(error));
        })
        .catch((error) => this.$log.error(error));
    },
  },
};
</script>

<style scoped>
.apply-for-project-form .main-title {
  font-size: 18.75pt !important;
}
.input-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
}

.title-modal {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  /* or 136% */

  text-align: center;
}
.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  font-family: "Roboto";
}
</style>

<style>
.create-project-form .v-messages__message {
  color: red !important;
}
</style>
